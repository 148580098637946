import { render, staticRenderFns } from "./GiftCard.vue?vue&type=template&id=5cd16bb8"
import script from "./GiftCard.vue?vue&type=script&lang=js"
export * from "./GiftCard.vue?vue&type=script&lang=js"
import style0 from "./GiftCard.vue?vue&type=style&index=0&id=5cd16bb8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports